const Logo = ({ className = "", variant = "white", width = 104, ...props }) => {
  const opts = { primaryColor: "#ff8700", secondaryColor: variant === "white" ? "#fff" : "#2a65d3" }
  return (
    <svg className={`${className}`.trim()} width={width} viewBox="0 0 104 28" {...props}>
      <path
        d="M12.268.001a12.33 12.33 0 00-4.672 23.715V15.87l-1.75 1.473-1.963-2.327 3.843-4.043V6.521h3.162v2.47l4-3.858s6.451 5.136 9.65 7.19A12.3 12.3 0 0012.267 0v.001z"
        fill={opts.primaryColor}
      />
      <path
        d="M103.57 9.321a2.679 2.679 0 01-3.205 2.613 2.676 2.676 0 112.424-4.51 2.686 2.686 0 01.781 1.897zM21.784 26.732H11.251V10.658h4.788v12.067h5.745v4.007zm11.841-6.519a2.833 2.833 0 00-2.845-2.843 2.71 2.71 0 00-2.161 1 2.831 2.831 0 000 3.693 2.681 2.681 0 002.16 1 2.814 2.814 0 002.846-2.843v-.007zm4.3 6.52H33.49v-1.632a4.541 4.541 0 01-1.28 1.165 5.155 5.155 0 01-2.572.641 6.079 6.079 0 01-4.149-1.61 6.462 6.462 0 01-2.168-5.09 6.45 6.45 0 012.222-5.14 6.01 6.01 0 014.057-1.529 5.792 5.792 0 012.505.554 5 5 0 011.388 1.07V13.93h4.437v12.8l-.005.003z"
        fill={opts.secondaryColor}
      />
      <path
        d="M100.862 13.155a3.766 3.766 0 01-2.357-.829h-.03v14.408h4.8V12.326h-.052a3.77 3.77 0 01-2.358.83M62.164 26.732h-4.438V20.29a4.98 4.98 0 00-.337-2.217 1.616 1.616 0 00-1.5-.88 1.89 1.89 0 00-1.7.96 4.2 4.2 0 00-.417 2.137v6.442h-4.435V20.29a5.19 5.19 0 00-.27-2.11 1.774 1.774 0 00-1.658-.973 1.856 1.856 0 00-1.618.839 1.96 1.96 0 00-.31.817c-.074.472-.108.95-.1 1.427v6.442h-4.432v-12.8h4.437v1.544a6.886 6.886 0 011.432-1.111 5.426 5.426 0 012.62-.636 4.852 4.852 0 013.081.961c.47.39.872.857 1.188 1.381a4.749 4.749 0 014.2-2.356c.88-.015 1.747.22 2.5.677.75.454 1.3 1.175 1.54 2.018.157.628.23 1.275.216 1.923v8.4l.001-.001zM78.5 21.907a4.526 4.526 0 01-3.3 4.658c-1.086.34-2.221.5-3.359.473-2.6 0-4.467-.64-5.6-1.922a4.664 4.664 0 01-1.083-3.209V13.93h4.436v7.122a2.341 2.341 0 00.564 1.734 2.633 2.633 0 003.337 0 2.345 2.345 0 00.564-1.734V13.93H78.5v7.977zm12.808-1.687a2.766 2.766 0 00-.833-2.03 2.738 2.738 0 00-2.012-.834 2.699 2.699 0 00-2.162 1 2.871 2.871 0 000 3.72 2.674 2.674 0 002.162 1 2.743 2.743 0 002.02-.828 2.772 2.772 0 00.825-2.036v.008zm4.3 6.513h-4.435v-1.632a4.552 4.552 0 01-1.279 1.165 5.162 5.162 0 01-2.573.641 6.076 6.076 0 01-4.148-1.61A6.461 6.461 0 0181 20.207a6.447 6.447 0 012.223-5.14 6.011 6.011 0 014.054-1.528c.866-.004 1.72.19 2.5.568.521.273.99.635 1.387 1.07V6.62h4.438l.006 20.113z"
        fill={opts.primaryColor}
      />
    </svg>
  )
}

export default Logo
