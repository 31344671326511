import Link from "next/link"
import Logo from "./logo"
import styles from "./navigation-sheet.module.scss"

const NavigationSheet = ({ data, onClickAddPropertyModal }) => {
  return (
    <>
      <div className={`${styles.navHeader} u-mb32`} id="navigationHeader">
        <Link href="/" prefetch={false}>
          <a>
            <Logo variant="colored" className={styles.mLogo} />
          </a>
        </Link>
      </div>
      <nav className={`${styles.navLinks} u-spbwy16`} id="navigationLinkList">
        {data.map((item, i) => {
          return (
            <>
              {item.external ? (
                <a className={styles.navLink} href={item.link}>{item.text}</a>
              ) : (
                <Link href={item.link} prefetch={false}>
                  <a className={styles.navLink} title={item.title}>
                    {item.text}
                  </a>
                </Link>
              )}
            </>
          )
        })}
        <button onClick={onClickAddPropertyModal} className={styles.navLink}>
          Add Property
        </button>
      </nav>
    </>
  )
}

export default NavigationSheet
